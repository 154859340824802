<template>
  <div class="child-activity">
    <div class="flex justify-between items-center mb-2">
      <p class="text-sm font-bold mb-2 text-neutral-500">Activity Item {{ index + 1 }}</p>
      <div class="flex justify-between items-center">
        <div v-if="index !== 0" class="flex justify-center items-center mr-5">
          <button
            class="mr-2 p-1 border rounded transition-all outline-none inline-flex justify-center items-center gap-2 bg-transparent border-yellow-primary text-yellow-primary hover:text-yellow-300 hover:border-yellow-300 focus:outline-none"
            @click="changePosition('up', index)"
          >
            <Chevron direction="up" height="16" width="16" color="#3b82f6" />
          </button>
          <button
            class="p-1 border rounded transition-all outline-none inline-flex justify-center items-center gap-2 bg-transparent border-yellow-primary text-yellow-primary hover:text-yellow-300 hover:border-yellow-300 focus:outline-none"
            :class="[isLastIndex ? 'border-grey-field hover:border-grey-field cursor-not-allowed' : '']"
            :disabled="isLastIndex"
            @click="changePosition('down', index)"
          >
            <Chevron direction="down" height="16" width="16" :color="isLastIndex ? '#D6D6D6' : '#3b82f6'" />
          </button>
        </div>
        <Button type="secondary" size="icon" :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')" @action="deleteItem(index)" v-if="index !== 0" />
      </div>
    </div>
    <div class="bg-neutral-100 p-4 rounded-lg mb-6" :class="{ 'is-invalid': isError?.error }">
      <div class="w-3/5">
        <div class="mb-4">
          <TextField type="text" isLabelRequire label="Title" v-model="data.title" :borderEnabled="true" placeholder="Child Activity Title" :error="error && error['title']" />
        </div>
        <div class="mb-4">
          <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Description<span class="text-red"> *</span></label>
          <div class="bg-white">
            <quill-editor v-model="data.description" :options="option" />
          </div>
          <div class="pl-3 text-system-error text-left mt-1 text-xs" v-if="error">
            {{ error['description'] }}
          </div>
        </div>
        <div class="mb-4">
          <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Type<span class="text-red"> *</span></label>
          <Dropdown
            :disabled="disableSelectActivityType"
            :options="activityType"
            optionLabel="label"
            v-model="activityItemType"
            :default="activityItemType"
            placeholder="Select Activity Type"
            :error="error && error['activityItemType']"
          />
        </div>

        <div class="py-4" v-if="visibleType">
          <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block" v-if="fileSelectionTitle"
            >{{ fileSelectionTitle }}<span class="text-red"> *</span> <span v-if="classIdError" class="text-xs text-neutral-300">({{ classIdError }})</span></label
          >
          <div class="gallery" v-if="activityItemType.value === 'VIDEO' || activityItemType.value === 'DOCUMENT'">
            <div class="relative">
              <label @click="toggleGallery()" class="label absolute right-0 p-3 cursor-pointer text-white font-medium mdmax:text-xs bg-yellow-primary rounded-tr-lg rounded-br-lg">Browse</label>
              <div class="input pl-3 pr-24 py-5 focus:outline-none focus:ring focus:ring-primary-500 border border-neutral-300 w-full rounded-lg text-neutral-300 mdmax:text-xs">
                {{ selectedVideo }}
              </div>
            </div>
            <div class="pl-3 text-system-error text-left mt-1 text-xs" v-if="error">
              {{ error['itemReferenceId'] }}
            </div>
          </div>
          <div class="gallery py-4" v-if="activityItemType.value === 'VIDEO'">
            <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Lower Resolution</label>
            <div class="relative">
              <label @click="toggleLowResGallery()" class="label absolute right-0 p-3 cursor-pointer text-white font-medium mdmax:text-xs bg-yellow-primary rounded-tr-lg rounded-br-lg">Browse</label>
              <div class="input pl-3 pr-24 py-5 focus:outline-none focus:ring focus:ring-primary-500 border border-neutral-300 w-full rounded-lg text-neutral-300 mdmax:text-xs">
                {{ selectedLowResVideo }}
              </div>
            </div>
            <div class="pl-3 text-system-error text-left mt-1 text-xs" v-if="error">
              {{ error['itemLowResReferenceId'] }}
            </div>
          </div>
          <div class="offline" v-else-if="activityItemType.value === 'OFFLINE_MEETING' && !isTemplateAct">
            <div class="mb-4 date">
              <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Start Time<span class="text-red"> *</span></label>
              <div class="relative">
                <span class="inline-block absolute right-4 top-1/2 transform -translate-y-1/2"><Calendar color="neutral-500" /></span>
                <datetime type="datetime" v-model="startDate" zone="Asia/Jakarta" format="yyyy-MM-dd HH:mm" :max-datetime="endDate" placeholder="Select Date"></datetime>
              </div>
              <div class="pl-3 text-system-error text-left mt-1 text-xs" v-if="error && error['startTime']">
                {{ error && error['startTime'] }}
              </div>
            </div>
            <div class="date">
              <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">End Time<span class="text-red"> *</span></label>
              <div class="relative">
                <span class="inline-block absolute right-4 top-1/2 transform -translate-y-1/2"><Calendar color="neutral-500" /></span>
                <datetime type="datetime" v-model="endDate" zone="Asia/Jakarta" format="yyyy-MM-dd HH:mm" :min-datetime="startDate" placeholder="Select Date"></datetime>
              </div>
              <div class="pl-3 text-system-error text-left mt-1 text-xs" v-if="error && error['endTime']">
                {{ error && error['endTime'] }}
              </div>
            </div>
            <div class="mt-4">
              <div class="flex gap-3">
                <div class="w-full">
                  <Dropdown
                    :options="filterInstructor"
                    v-model="selectedInstructor"
                    :default="selectedInstructor"
                    :disabled="!startDate || !endDate"
                    placeholder="Select Instructor"
                    label="Activity Item Instructor (optional)"
                    optionLabel="name"
                    @search="getInstructorList"
                    :isLoadData="isLoadDataInstructor"
                    enableSearch
                  />
                </div>
                <div class="flex items-center pt-5" v-if="selectedInstructor">
                  <Button buttonText="Reset" type="tertiary" @action="deleteInstructorSelected()" />
                </div>
              </div>
              <p class="text-xs mt-2 text-neutral-400 pl-3">If you leave it empty, instructor will be taken from Activity Instructor / Instructor (Default)</p>
            </div>
          </div>
          <div class="offline" v-else-if="activityItemType.value === 'PRETEST' || activityItemType.value === 'QUIZ' || activityItemType.value === 'EXAM' || activityItemType.value === 'POST_TEST'">
            <div class="mb-4">
              <Dropdown
                :options="activityReference"
                v-model="itemReferenceId"
                :default="itemReferenceId"
                optionKey="id"
                optionLabel="name"
                placeholder="Select"
                :error="error && error['itemReferenceId']"
                @search="getExerciseList"
                :isLoadData="isLoadDataExercise"
                enableSearch
              />
            </div>
            <div class="mb-4">
              <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Time Constraint<span class="text-red"> *</span></label>
              <Dropdown :options="constraintList" optionLabel="label" v-model="constraint" :default="constraint" />
              <span ref="warn"></span>
            </div>
            <div v-if="constraint && (constraint.value === 1 || constraint.value === 2 || constraint.value === 3)" class="mb-4 date">
              <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Start Time<span class="text-red"> *</span></label>
              <div class="relative">
                <span class="inline-block absolute right-4 top-1/2 transform -translate-y-1/2"><Calendar color="neutral-500" /></span>
                <datetime type="datetime" v-model="startDate" zone="Asia/Jakarta" format="yyyy-MM-dd HH:mm" :max-datetime="endDate" placeholder="Select Date"></datetime>
              </div>
              <div class="pl-3 text-system-error text-left mt-1 text-xs" v-if="error && error['startTime']">
                {{ error && error['startTime'] }}
              </div>
              <div class="text-xs mt-2 ml-4 text-gray-500">Exercise can be accessed in LMS after Start Time</div>
            </div>
            <div v-if="constraint && (constraint.value === 1 || constraint.value === 3)" class="date">
              <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">End Time<span class="text-red"> *</span></label>
              <div class="relative" v-if="!this.duration">
                <span class="inline-block absolute right-4 top-1/2 transform -translate-y-1/2"><Calendar color="neutral-500" /></span>
                <datetime type="datetime" id="myDatetime" v-model="endDate" zone="Asia/Jakarta" format="yyyy-MM-dd HH:mm" :min-datetime="startDate" placeholder="Select Date"></datetime>
              </div>
              <div class="relative date2" v-else>
                <span class="inline-block absolute right-4 top-1/2 transform -translate-y-1/2"><Calendar color="neutral-500" /></span>
                <datetime
                  type="datetime"
                  id="myDatetime"
                  v-model="endDate"
                  zone="Asia/Jakarta"
                  format="yyyy-MM-dd HH:mm"
                  :min-datetime="startDate"
                  placeholder="Select Date"
                  class="cursor-not-allowed"
                ></datetime>
              </div>
              <div class="pl-3 text-system-error text-left mt-1 text-xs" v-if="error && error['endTime']">
                {{ error && error['endTime'] }}
              </div>
            </div>
            <div v-if="constraint && (constraint.value === 2 || constraint.value === 3)" class="mb-4 mt-4">
              <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Duration<span class="text-red"> *</span></label>
              <Dropdown :options="durationList" optionLabel="label" v-model="duration" :default="duration" placeholder="Select Duration" :error="error && error['durationInMinutes']" />
            </div>
          </div>
          <div class="offline" v-else-if="activityItemType.value === 'MANUAL_TASK'">
            <div class="mb-4">
              <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Time Constraint<span class="text-red"> *</span></label>
              <Dropdown :options="constraintListManualTask" optionLabel="label" v-model="constraint" :default="constraint" />
              <span ref="warn"></span>
            </div>
            <div v-if="constraint && constraint.value === 1" class="mb-4 date">
              <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Start Time<span class="text-red"> *</span></label>
              <div class="relative">
                <span class="inline-block absolute right-4 top-1/2 transform -translate-y-1/2"><Calendar color="neutral-500" /></span>
                <datetime type="datetime" v-model="startDate" zone="Asia/Jakarta" format="yyyy-MM-dd HH:mm" :max-datetime="endDate" placeholder="Select Date"></datetime>
              </div>
              <div class="pl-3 text-system-error text-left mt-1 text-xs" v-if="error && error['startTime']">
                {{ error && error['startTime'] }}
              </div>
              <div class="text-xs mt-2 ml-4 text-gray-500">Manual task can be accessed in LMS after Start Time</div>
            </div>
            <div v-if="constraint && (constraint.value === 1 || constraint.value === 2)" class="mb-4 date">
              <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">End Time<span class="text-red"> *</span></label>
              <div class="relative">
                <span class="inline-block absolute right-4 top-1/2 transform -translate-y-1/2"><Calendar color="neutral-500" /></span>
                <datetime
                  :disabled="constraint.value === 2 ? false : startDate ? false : true"
                  type="datetime"
                  v-model="endDate"
                  zone="Asia/Jakarta"
                  format="yyyy-MM-dd HH:mm"
                  :min-datetime="constraint.value === 2 ? null : startDate"
                  placeholder="Select Date"
                ></datetime>
              </div>
              <div class="pl-3 text-system-error text-left mt-1 text-xs" v-if="error && error['endTime']">
                {{ error && error['endTime'] }}
              </div>
            </div>
          </div>
          <div class="offline" v-else-if="activityItemType.value === 'RECURRING_ONLINE' || (activityItemType.value === 'WEBINAR' && !isTemplateAct)">
            <div :class="`mb-4 ${activityItemType.value === 'WEBINAR' ? 'date' : ''}`">
              <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">{{ activityItemType.value === 'WEBINAR' ? 'Date' : 'Day' }}<span class="text-red"> *</span></label>
              <div v-if="activityItemType.value === 'WEBINAR'" class="relative">
                <span class="inline-block absolute right-4 top-1/2 transform -translate-y-1/2"><Calendar color="neutral-500" /></span>
                <datetime v-model="startDate" zone="Asia/Jakarta" format="yyyy-MM-dd" placeholder="Select Date"></datetime>
              </div>
              <Dropdown v-else :options="dayOfWeekList" optionLabel="label" v-model="dayOfWeek" :default="dayOfWeek" placeholder="Select Day" :error="error && error['dayOfWeek']" />
            </div>
            <div class="mb-4">
              <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Start Hour<span class="text-red"> *</span></label>
              <div class="relative time">
                <span class="inline-block absolute right-4 top-1/2 transform -translate-y-1/2 z-1"><Calendar color="neutral-500" /></span>
                <vue-timepicker
                  :key="`start-${counter}`"
                  v-model="startTimeOfDay"
                  hide-clear-button
                  close-on-complete
                  placeholder="Select Time"
                  :hour-range="getStartHourRange()"
                  :minute-range="getStartMinuteRange()"
                  :disabled="firstDisable"
                ></vue-timepicker>
              </div>
              <div class="pl-3 text-system-error text-left mt-1 text-xs" v-if="error && error['startTimeOfDay']">
                {{ error && error['startTimeOfDay'] }}
              </div>
            </div>
            <div class="">
              <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">End Hour<span class="text-red"> *</span></label>
              <div class="relative time">
                <span class="inline-block absolute right-4 top-1/2 transform -translate-y-1/2 z-1"><Calendar color="neutral-500" /></span>
                <vue-timepicker
                  :key="`end-${counter}`"
                  v-model="endTimeOfDay"
                  hide-clear-button
                  close-on-complete
                  placeholder="Select Time"
                  :hour-range="getEndHourRange()"
                  :minute-range="getEndMinuteRange()"
                  :disabled="secondDisable"
                ></vue-timepicker>
              </div>
              <div class="pl-3 text-system-error text-left mt-1 text-xs" v-if="error && error['endTimeOfDay']">
                {{ error && error['endTimeOfDay'] }}
              </div>
            </div>
            <div class="mt-4 w-full" v-if="activityItemType.value === 'WEBINAR' && !isTemplateAct">
              <div class="flex gap-3">
                <div class="w-full">
                  <Dropdown
                    :options="filterInstructor"
                    v-model="selectedInstructor"
                    :default="selectedInstructor"
                    :disabled="thirdDisable"
                    placeholder="Select Instructor"
                    label="Activity Item Instructor (optional)"
                    optionLabel="name"
                    @search="getInstructorList"
                    :isLoadData="isLoadDataInstructor"
                    enableSearch
                  />
                </div>
                <div class="flex items-center pt-5" v-if="selectedInstructor">
                  <Button buttonText="Reset" type="tertiary" @action="deleteInstructorSelected()" />
                </div>
              </div>
              <p class="text-xs mt-2 text-neutral-400 pl-3">If you leave it empty, instructor will be taken from Activity Instructor / Instructor (Default)</p>
            </div>
            <div class="mt-4" v-if="activityItemType.value === 'WEBINAR' && !isTemplateAct">
              <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Webinar Link Setting (optional)</label>
              <div class="flex gap-10 items-center mt-3 mb-8">
                <div>
                  <input type="radio" v-model="autoGenerated" :value="false" :id="`manual-${parentIndex}-${index}`" />
                  <label :for="`manual-${parentIndex}-${index}`" class="text-black font-medium"><span>Manual Input</span></label>
                </div>
                <div>
                  <input type="radio" v-model="autoGenerated" :value="true" :id="`auto-${parentIndex}-${index}`" />
                  <label :for="`auto-${parentIndex}-${index}`">
                    <p class="relative">
                      <span class="block font-medium">Auto-generated Zoom Link</span><i class="text-xs not-italic text-neutral-500 absolute font-normal">Zoom Integration required</i>
                    </p>
                  </label>
                </div>
              </div>
              <div v-if="!autoGenerated">
                <TextField type="text" v-model="webinarLink" borderEnabled placeholder="Enter webinar link" />
                <p class="text-xs mt-2 text-neutral-400 pl-3">If empty, activity will use link from General tab</p>
              </div>
              <div v-if="isEditMode && autoGenerated && defaultStateForAutoGenerated" class="flex items-center gap-2">
                <img src="@/assets/images/zoom.png" class="w-6" />
                <p class="text-xs font-medium text-yellow">{{ webinarLink }}</p>
              </div>
            </div>
          </div>
          <div class="offline" v-else-if="activityItemType.value === 'OPEN_LINK'">
            <div class="mb-4">
              <TextField type="text" label="Label" v-model="forLabel" borderEnabled isLabelRequire placeholder="Link Label" :error="error && error['label']" />
            </div>
            <div class="">
              <TextField type="text" label="Link" v-model="forLink" borderEnabled isLabelRequire placeholder="Link" :error="error && error['link']" />
            </div>
          </div>
          <div v-else-if="activityItemType?.value === 'REFLECTIVE_JOURNAL_ESSAY'">
            <div class="mb-4">
              <TextArea
                label="Soal 1"
                isLabelRequire
                :borderEnabled="true"
                v-model="data.reflectiveJournalQuestion1"
                placeholder="Soal 1"
                :error="error && error['soal_1']"
              />
            </div>
            <div class="mb-4">
              <TextArea
                label="Soal 2"
                isLabelRequire
                :borderEnabled="true"
                v-model="data.reflectiveJournalQuestion2"
                placeholder="Soal 2"
                :error="error && error['soal_2']"
              />
            </div>
          </div>
          <Dropdown
            v-else-if="activityItemType.value != 'OFFLINE_MEETING' && activityItemType.value != 'WEBINAR' && activityItemType.value != 'REFLECTIVE_JOURNAL' && activityItemType.value != 'REFLECTIVE_JOURNAL_ESSAY' && activityItemType.value != 'DOCUMENT'"
            :options="activityReference"
            v-model="itemReferenceId"
            :default="itemReferenceId"
            optionKey="id"
            optionLabel="name"
            :placeholder="`Select ${activityItemType.label}`"
            :error="error && error['itemReferenceId']"
            @search="getExerciseList"
            :isLoadData="isLoadDataExercise"
            enableSearch
          />
          <div class="mt-4" v-if="activityItemType.value === 'INSTRUCTOR_FEEDBACK'">
            <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Select Instructor ( Feedback )<span class="text-red"> *</span></label>
            <Dropdown
              :options="list_instructur"
              v-model="selectedInstructor"
              :default="selectedInstructor"
              placeholder="Select Instructor"
              optionLabel="name"
              @search="getInstructorList"
              :isLoadData="isLoadDataInstructor"
              enableSearch
            />
          </div>
          <div class="mt-4" v-show="activityItemType.value === 'PRETEST' || activityItemType.value === 'QUIZ' || activityItemType.value === 'EXAM' || activityItemType.value === 'POST_TEST'">
            <TextField type="number" label="Passing Grade ( 0-100 )" v-model="minimumScore" borderEnabled placeholder="Set Passing Grade" :error="error && error['minimumScore']" />
          </div>
          <div class="flex items-center mt-3 gap-10">
            <div class="" v-show="activityItemType.value === 'PRETEST' || activityItemType.value === 'QUIZ' || activityItemType.value === 'EXAM' || activityItemType.value === 'MANUAL_TASK' || activityItemType.value === 'POST_TEST'">
              <input type="checkbox" :id="activityItemType.value + '-limit-' + parentIndex + index" v-model="isNotRetakeable" class="styled-checkbox" />
              <label :for="activityItemType.value + '-limit-' + parentIndex + index" class="text-sm">Limit to 1 Response</label>
              <p class="text-xs pl-4 ml-2 text-neutral-400 mb-2">Student can only submit 1 response</p>
            </div>
          </div>
          <div class="" v-show="activityItemType.value === 'MANUAL_TASK' && selectedWorkflow?.code === 'PRAKERJA_SCHEMA_NORMAL'">
            <input type="checkbox" :id="activityItemType.value + '-certificate-' + parentIndex + index" v-model="data.generated_certificate" class="styled-checkbox" />
            <label :for="activityItemType.value + '-certificate-' + parentIndex + index" class="text-sm">Generate Certificate</label>
            <p class="text-xs pl-4 ml-2 text-neutral-400 mb-2">Trigger to generate certificate</p>
          </div>
          <div class="flex items-center mb-4 mt-5" v-show="activityItemType.value === 'DOCUMENT'">
            <input type="checkbox" :id="'activity-' + parentIndex + '-limit-' + index" v-model="data.download_status" class="styled-checkbox" />
            <label :for="'activity-' + parentIndex + '-limit-' + index" class="text-sm">Allow Student to download document</label>
          </div>
        </div>
      </div>
    </div>
    <Modal :modalVisible="visibleGallery" @close="toggleGallery()" width="80%" id="gallery">
      <template slot="modal-content">
        <GalleryModal @selectVideo="setSelectedVideo" :modalTitle="`Select ${activityItemTypeSelected?.toLowerCase()}`" :activityType="activityItemTypeSelected" @close="toggleGallery()" />
      </template>
    </Modal>
    <Modal :modalVisible="visibleLowResGallery" @close="toggleLowResGallery()" width="80%" id="galleryLowRes">
      <template slot="modal-content">
        <GalleryModal @selectVideo="setSelectedLowResVideo" :modalTitle="`Select ${activityItemTypeSelected?.toLowerCase()}`" :activityType="activityItemTypeSelected" @close="toggleLowResGallery()" />
      </template>
    </Modal>
  </div>
</template>
<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import { mapActions, mapGetters } from 'vuex'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import moment from 'moment'
export default {
  components: {
    quillEditor,
    VueTimepicker,
    Checkbox: () => import(/* webpackChunkName: "icons" */ '@/components/Form/Checkbox'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    TextArea: () => import(/* webpackChunkName: "TextArea" */ '@/components/Form/TextArea'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal'),
    GalleryModal: () => import(/* webpackChunkName: "GalleryModal" */ '@/components/GalleryModal/GalleryModal'),
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Chevron'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    Calendar: () => import(/* webpackChunkName: "icons" */ '@/components/Icons/Calendar')
  },
  data() {
    return {
      isNotRetakeable: false,
      counter: 0,
      canUpdate: false,
      firstMounted: true,
      forLabel: null,
      forLink: null,
      defaultStateForAutoGenerated: null,
      autoGenerated: false,
      webinarLink: null,
      firstDisable: true,
      secondDisable: true,
      thirdDisable: true,
      durationDisabled: false,
      visibleType: false,
      limited: true,
      fileSelectionTitle: null,
      visibleGallery: false,
      visibleLowResGallery: false,
      selectedVideo: null,
      selectedLowResVideo: null,
      itemReferenceId: null,
      minimumScore: 0,
      activityItemType: null,
      duration: null,
      constraint: null,
      dayOfWeek: null,
      startTimeOfDay: null,
      endTimeOfDay: null,
      forWebinarDate: null,
      startDate: null,
      endDate: null,
      selectedInstructor: null,
      isLoadDataInstructor: false,
      option: {
        modules: {
          toolbar: [['bold', 'italic', 'underline', 'strike'], [{ list: 'ordered' }, { list: 'bullet' }], [{ size: ['small', false, 'large', 'huge'] }], ['link'], ['clean']]
        }
      },
      activityType: [
        {
          label: 'Video',
          value: 'VIDEO'
        },
        {
          label: 'Document',
          value: 'DOCUMENT'
        },
        {
          label: 'PreTest',
          value: 'PRETEST'
        },
        {
          label: 'Quiz',
          value: 'QUIZ'
        },
        {
          label: 'PostTest',
          value: 'EXAM'
        },
        {
          label: 'Post Test With Authentication',
          value: 'POST_TEST'
        },
        {
          label: 'Assignment',
          value: 'ASSIGNMENT'
        },
        {
          label: 'Tugas',
          value: 'MANUAL_TASK'
        },
        {
          label: 'Offline Meeting',
          value: 'OFFLINE_MEETING'
        },
        {
          label: 'Link',
          value: 'OPEN_LINK'
        },
        {
          label: 'Consultation Session',
          value: 'RECURRING_ONLINE'
        },
        {
          label: 'Webinar',
          value: 'WEBINAR'
        },
        {
          label: 'Instructor Feedback',
          value: 'INSTRUCTOR_FEEDBACK'
        },
        {
          label: 'Journal',
          value: 'REFLECTIVE_JOURNAL'
        },
        {
          label: 'Journal - Isian',
          value: 'REFLECTIVE_JOURNAL_ESSAY'
        }
      ],
      itemOptions: [],
      exerciseType: null,
      classIdError: null,
      dayOfWeekList: [
        {
          label: 'Sunday',
          value: 1
        },
        {
          label: 'Monday',
          value: 2
        },
        {
          label: 'Tuesday',
          value: 3
        },
        {
          label: 'Wednesday',
          value: 4
        },
        {
          label: 'Thursday',
          value: 5
        },
        {
          label: 'Friday',
          value: 6
        },
        {
          label: 'Saturday',
          value: 7
        }
      ],
      durationList: [
        {
          label: '30 mins',
          value: 30
        },
        {
          label: '45 mins',
          value: 45
        },
        {
          label: '1 hour',
          value: 60
        },
        {
          label: '1 hour and 30 mins',
          value: 90
        },
        {
          label: '100 mins',
          value: 100
        },
        {
          label: '2 hours',
          value: 120
        },
        {
          label: '2 hours and 30 mins',
          value: 150
        },
        {
          label: '3 hours',
          value: 180
        }
      ],
      constraintList: [
        {
          label: 'No time constraint',
          value: 0
        },
        {
          label: 'Start time - End time',
          value: 1
        },
        {
          label: 'Start time - Duration',
          value: 2
        },
        {
          label: 'Start time - Duration - End time',
          value: 3
        }
      ],
      constraintListManualTask: [
        {
          label: 'No time constraint',
          value: 0
        },
        {
          label: 'Start time - End time',
          value: 1
        },
        {
          label: 'End time only',
          value: 2
        }
      ],
      isLoadDataExercise: false
    }
  },
  props: {
    isError: [Object, Boolean],
    data: {
      type: Object,
      default: () => {}
    },
    isTemplateAct: { type: Boolean, default: false },
    isIndependent: {
      type: Boolean,
      default: false
    },
    isLastIndex: { type: Boolean, default: false },
    index: {
      type: Number,
      default: 0
    },
    parentIndex: {
      type: Number,
      default: 0
    },
    initialInstructorList: { type: [Object, Array], default: null },
    primaryInstructor: {
      type: [Object, Array],
      default: () => {}
    },
    error: null,
    classId: String,
    selectedWorkflow: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters('exercise', ['list_exercise']),
    ...mapGetters('instructur', ['list_instructur']),
    today() {
      return new Date().toDateString()
    },
    disableSelectActivityType() {
      const id = this.data.id
      const type = this.activityItemType && this.activityItemType.value ? true : false
      return this.isEditMode && id && type
    },
    filterInstructor() {
      return this.list_instructur
    },
    isEditMode() {
      return this.$route.meta.mode === 'Edit'
    },
    isCopyMode() {
      return this.$route.meta.mode === 'Copy'
    },
    customerId() {
      return localStorage.getItem('client')
    },
    activityReference() {
      if (this.data.activityItemType === 'INSTRUCTOR_FEEDBACK') return this.list_exercise && this.list_exercise.filter((item) => item.exercise_type_name === 'SURVEY')
      if (this.data.activityItemType === 'POST_TEST') return this.list_exercise && this.list_exercise.filter((item) => item.exercise_type_name === 'EXAM')
      const filteredData = this.list_exercise && this.list_exercise.filter((item) => item.exercise_type_name === this.exerciseType)
      return filteredData
    },
    activityItemTypeSelected() {
      return this.data?.activityItemType
    }
  },
  watch: {
    activityItemType(val) {
      if (!this.data.activityItemType || !this.firstMounted) {
        this.data.itemReferenceId = null
        this.data.includeInCertificate = false
        this.data.download_status = false
        this.data.warned = false
        if (this.data.startTime !== undefined) delete this.data.startTime
        if (this.data.endTime !== undefined) delete this.data.endTime
        if (this.data.label !== undefined) delete this.data.label
        if (this.data.link !== undefined) delete this.data.link
        if (this.data.dayOfWeek !== undefined) delete this.data.dayOfWeek
        if (this.data.startTimeOfDay !== undefined) delete this.data.startTimeOfDay
        if (this.data.endTimeOfDay !== undefined) delete this.data.endTimeOfDay
        if (this.data.meeting_link_auto_generated !== undefined) delete this.data.meeting_link_auto_generated
        if (this.data.retakeable !== undefined) delete this.data.retakeable
        if (this.data.minimumScore !== undefined) delete this.data.minimumScore
        if (this.data.expert_id !== undefined) delete this.data.expert_id
        if (this.data.durationInMinutes !== undefined) delete this.data.durationInMinutes
        if (this.data.timeConstraint !== undefined) delete this.data.timeConstraint

        if (val.value === 'QUIZ' || val.value === 'PRETEST' || val.value === 'EXAM' || val.value === 'POST_TEST' || val.value === 'MANUAL_TASK') {
          this.data.timeConstraint = this.constraintList[0].value
          this.constraint = this.constraintList[0]
        }

        this.selectedVideo = null
        this.selectedLowResVideo = null
        this.startDate = null
        this.endDate = null
        this.selectedInstructor = null
        this.itemReferenceId = null
        this.duration = null
        this.dayOfWeek = null
        this.startTimeOfDay = null
        this.endTimeOfDay = null
        this.webinarLink = null
        this.firstDisable = true
        this.secondDisable = true
        this.thirdDisable = true
        this.limited = true
        this.autoGenerated = false
        this.minimumScore = 0
        this.counter++
        this.canUpdate = true
      }

      this.firstMounted = false

      this.data.activityItemType = val.value
      this.fileSelectionTitle = null
      this.exerciseType = val.value === 'INSTRUCTOR_FEEDBACK' ? 'SURVEY' : val.value
      if (val.value === 'INSTRUCTOR_FEEDBACK') {
        if (this.data.expert_id) {
          this.selectedInstructor = this.list_instructur.filter((item) => item.id == this.data.expert_id)[0]
        }
      }
      if (val) this.visibleType = true
      if (val.value === 'VIDEO' || val.value === 'DOCUMENT') {
        this.fileSelectionTitle = val.value === 'VIDEO' ? 'Original Resolution (Required)' : 'File'
        if (!this.data.itemReferenceId && this.data.itemReferenceId === "") {
          this.data.itemReferenceId = 0
        }
      } else if (val.value === 'MANUAL_TASK') {
        this.fileSelectionTitle = null
        this.data.itemReferenceId = 0
        // this.limited = this.data?.retakeable
        this.isNotRetakeable = this.isEditMode ? !this.data.retakeable : this.data.retakeable
      } else if (val.value === 'OFFLINE_MEETING') {
        this.fileSelectionTitle = null
        this.data.itemReferenceId = 0
        if (!this.isEditMode) {
          this.data.startTime = null
          this.data.endTime = null
        }
      } else if (val.value === 'WEBINAR') {
        this.fileSelectionTitle = null
        this.data.itemReferenceId = 0
        if (!this.isEditMode) {
          this.data.startTime = null
          this.data.endTime = null
          this.data.link = null
          this.data.meeting_link_auto_generated = false
        }
      } else if (val.value === 'OPEN_LINK') {
        this.fileSelectionTitle = null
        this.data.itemReferenceId = 0
        if (!this.isEditMode) {
          this.data.label = null
          this.data.link = null
        }
      } else if (val.value === 'RECURRING_ONLINE') {
        this.fileSelectionTitle = null
        this.data.itemReferenceId = 0
        if (!this.isEditMode) {
          this.data.dayOfWeek = null
          this.data.startTimeOfDay = null
          this.data.endTimeOfDay = null
        }
      } else if (val.value === 'REFLECTIVE_JOURNAL') {
        this.minimumScore = this.data.minimumScore || 0
      } else if (val.value === 'REFLECTIVE_JOURNAL_ESSAY') {
        // do sth
      } else {
        this.fileSelectionTitle = val.label
        this.exerciseType = val.value
        this.itemReferenceId = null
        this.getExercise()
        if (val.value === 'QUIZ' || val.value === 'PRETEST' || val.value === 'EXAM' || val.value === 'POST_TEST') {
          this.minimumScore = this.data.minimumScore || 0
          // this.getExerciseList()
        }
        if (val.value === 'QUIZ' || val.value === 'PRETEST' || val.value === 'EXAM' || val.value === 'MANUAL_TASK' || val.value === 'POST_TEST') {
          this.limited = this.data.retakeable
        }
      }
    },
    webinarLink(val) {
      this.data.link = val
      if (this.canUpdate) this.$emit('onChange')
    },
    forLabel(val) {
      this.data.label = val
      if (this.canUpdate) this.$emit('onChange')
    },
    forLink(val) {
      this.data.link = val
      if (this.canUpdate) this.$emit('onChange')
    },
    autoGenerated(val) {
      this.data.meeting_link_auto_generated = val
      if (this.data.meeting_link_auto_generated && !this.isEditMode) {
        this.data.link = null
      }
      if (this.canUpdate) this.$emit('onChange')
    },
    selectedInstructor(val) {
      if (val) this.data.expert_id = val.id
      if (this.canUpdate) this.$emit('onChange')
    },
    itemReferenceId(val) {
      if (val) this.data.itemReferenceId = val.id
      if (this.canUpdate) this.$emit('onChange')
    },
    duration(val) {
      if (val && this.activityItemType && (this.activityItemType.value === 'PRETEST' || this.activityItemType.value === 'QUIZ' || this.activityItemType.value === 'EXAM' || this.activityItemType.value === 'POST_TEST'))
        this.data.durationInMinutes = val.value
      if (this.canUpdate) this.$emit('onChange')
    },
    constraint(val) {
      if (val && this.activityItemType && (this.activityItemType.value === 'PRETEST' || this.activityItemType.value === 'QUIZ' || this.activityItemType.value === 'EXAM' || this.activityItemType.value === 'POST_TEST')) {
        this.data.timeConstraint = val.value
        switch (val.value) {
          case 0:
            this.duration = this.data.durationInMinutes = null
            this.endDate = this.data.endTime = null
            this.startDate = this.data.startTime = null
            break
          case 1:
            this.duration = this.data.durationInMinutes = null
            break
          case 2:
            this.endDate = this.data.endTime = null
            break
        }
      }

      if (val && this.activityItemType && this.activityItemType.value === 'MANUAL_TASK') {
        this.data.timeConstraint = val.value
        switch (val.value) {
          case 0:
            this.endDate = this.data.endTime = null
            this.startDate = this.data.startTime = null
            break
          case 1:
            this.endDate = this.data.endTime ? this.data.endTime : null
            this.startDate = this.data.startTime ? this.data.startTime : null
            break
          case 2:
            this.startDate = this.data.startTime = null
            this.endDate = this.data.endTime ? this.data.endTime : null
            break
        }
      }
      if (this.canUpdate) this.$emit('onChange')
    },
    limited(val) {
      if (this.activityItemType?.value === 'PRETEST' || this.activityItemType?.value === 'QUIZ' || this.activityItemType?.value === 'EXAM' || this.activityItemType?.value === 'MANUAL_TASK' || this.activityItemType?.value === 'POST_TEST') {
        this.data.retakeable = val
      }

      if (this.canUpdate) this.$emit('onChange')
    },
    isNotRetakeable(val) {
      if (this.activityItemType?.value === 'PRETEST' || this.activityItemType?.value === 'QUIZ' || this.activityItemType?.value === 'EXAM' || this.activityItemType?.value === 'MANUAL_TASK' || this.activityItemType?.value === 'POST_TEST') {
        this.data.retakeable = !val
      }

      if (this.canUpdate) this.$emit('onChange')
    },
    minimumScore(val) {
      if (val < 0) {
        this.minimumScore = 0
      }
      if (val > 100) {
        this.minimumScore = 100
      }
      if (this.activityItemType.value === 'PRETEST' || this.activityItemType.value === 'QUIZ' || this.activityItemType.value === 'EXAM' || this.activityItemType?.value === 'POST_TEST') this.data.minimumScore = val
      if (this.canUpdate) this.$emit('onChange')
    },
    dayOfWeek(val) {
      if (val) {
        this.firstDisable = false
        this.data.dayOfWeek = val.value
      }
      if (this.canUpdate) this.$emit('onChange')
    },
    startTimeOfDay(val) {
      if (val) {
        this.secondDisable = false
        if (this.activityItemType.value === 'WEBINAR') {
          if (val.HH && val.mm) {
            this.data.startTime = moment(this.forWebinarDate + ' ' + `${val.HH}:${val.mm}:00`).format('YYYY-MM-DDTHH:mm:ss+0700')
            if (this.isEditMode && this.endTimeOfDay) {
              this.data.endTime = moment(this.forWebinarDate + ' ' + `${this.endTimeOfDay.HH}:${this.endTimeOfDay.mm}:00`).format('YYYY-MM-DDTHH:mm:ss+0700')
            }
          }
        } else {
          this.data.startTimeOfDay = `${val.HH}:${val.mm}:00`
        }
      }
      if (this.canUpdate) this.$emit('onChange')
    },
    endTimeOfDay(val) {
      this.thirdDisable = false
      if (val) {
        if (this.activityItemType.value === 'WEBINAR') {
          if (val.HH && val.mm) {
            this.data.endTime = moment(this.forWebinarDate + ' ' + `${val.HH}:${val.mm}:00`).format('YYYY-MM-DDTHH:mm:ss+0700')
            if (this.isEditMode && this.startTimeOfDay) {
              this.data.startTime = moment(this.forWebinarDate + ' ' + `${this.startTimeOfDay.HH}:${this.startTimeOfDay.mm}:00`).format('YYYY-MM-DDTHH:mm:ss+0700')
            }
          }
        } else {
          this.data.endTimeOfDay = `${val.HH}:${val.mm}:00`
        }
      }
      if (this.canUpdate) this.$emit('onChange')
    },
    startDate(val) {
      if (val) {
        this.firstDisable = false
        if (this.activityItemType.value === 'WEBINAR') {
          this.forWebinarDate = moment(val).format('YYYY-MM-DD')
          if (this.startTimeOfDay && this.endTimeOfDay) {
            let date = moment(val).format('YYYY-MM-DD')
            this.data.startTime = moment(date + ' ' + `${this.startTimeOfDay.HH}:${this.startTimeOfDay.mm}:00`).format('YYYY-MM-DDTHH:mm:ss+0700')
            this.data.endTime = moment(date + ' ' + `${this.endTimeOfDay.HH}:${this.endTimeOfDay.mm}:00`).format('YYYY-MM-DDTHH:mm:ss+0700')
          }
        } else {
          this.data.startTime = val.substring(0, 10) + 'T' + val.substring(11, 19) + 'Z'
        }
      }
      if (this.canUpdate) this.$emit('onChange')
    },
    endDate(val) {
      if (val) {
        this.durationDisabled = true
        this.data.endTime = val.substring(0, 10) + 'T' + val.substring(11, 19) + 'Z'
      }
      if (this.canUpdate) this.$emit('onChange')
    }
  },
  mounted() {
    console.log(this.data)
    if (this.data.activityItemType) {
      const defaultValue = JSON.parse(JSON.stringify(this.data.activityItemType))
      const temp = this.activityType.filter((item) => {
        return item.value === defaultValue
      })[0]
      this.activityItemType = temp
      if (this.activityItemType.value === 'VIDEO' || this.activityItemType.value === 'DOCUMENT') {
        this.selectedVideo = this.data.itemReferenceId ? this.data.itemReferenceId : null
        if (this.activityItemType.value === 'VIDEO') {
          this.selectedLowResVideo = this.data.itemLowResReferenceId ? this.data.itemLowResReferenceId : null
        }
      } else if (this.activityItemType.value === 'OFFLINE_MEETING') {
        if (this.data.expert_id) this.getInstructorList(null, true)
        this.startDate = this.data.startTime ? this.data.startTime : null
        this.endDate = this.data.endTime ? this.data.endTime : null
      } else if (this.activityItemType.value === 'RECURRING_ONLINE') {
        if (this.data.expert_id) this.getInstructorList(null, true)
        if (this.data.dayOfWeek) {
          this.dayOfWeek = this.dayOfWeekList.filter((item) => {
            return item.value === this.data.dayOfWeek
          })[0]
        }
        if (this.data.startTimeOfDay) {
          this.startTimeOfDay = {
            HH: this.data.startTimeOfDay.split(':')[0],
            mm: this.data.startTimeOfDay.split(':')[1]
          }
        }
        if (this.data.endTimeOfDay) {
          this.endTimeOfDay = {
            HH: this.data.endTimeOfDay.split(':')[0],
            mm: this.data.endTimeOfDay.split(':')[1]
          }
        }
      } else if (this.activityItemType.value === 'WEBINAR') {
        if (this.data.expert_id) this.getInstructorList(null, true)
        this.startDate = this.data.startTime ? this.data.startTime : null
        if (this.data.startTime) {
          this.forWebinarDate = moment(this.data.startTime).format('YYYY-MM-DD')
          let startTimeOfDay = moment(this.data.startTime).format('HH:mm:ss')
          this.startTimeOfDay = {
            HH: startTimeOfDay.split(':')[0],
            mm: startTimeOfDay.split(':')[1]
          }
        }
        if (this.data.endTime) {
          let endTimeOfDay = moment(this.data.endTime).format('HH:mm:ss')
          this.endTimeOfDay = {
            HH: endTimeOfDay.split(':')[0],
            mm: endTimeOfDay.split(':')[1]
          }
        }
        this.webinarLink = this.data.link ? this.data.link : null
        this.autoGenerated = this.data.meeting_link_auto_generated ? this.data.meeting_link_auto_generated : false
        this.defaultStateForAutoGenerated = this.data.meeting_link_auto_generated ? this.data.meeting_link_auto_generated : null
      } else if (this.activityItemType.value === 'MANUAL_TASK') {
        this.constraint = this.defineTimeConstrant(this.data.startTime, this.data.endTime)
        this.startDate = this.data.startTime ? this.data.startTime : null
        this.endDate = this.data.endTime ? this.data.endTime : null
        this.isNotRetakeable = this.isEditMode ? !this.data.retakeable : this.data.retakeable
      } else if (this.activityItemType.value === 'OPEN_LINK') {
        this.forLabel = this.data.label ? this.data.label : null
        this.forLink = this.data.link ? this.data.link : null
      } else {
        this.getExercise()
      }
    }
  },
  methods: {
    ...mapActions('instructur', ['GET_LIST_INSTRUCTUR', 'RESET_INSTRUCTUR_LIST']),
    ...mapActions('exercise', ['GET_LIST_EXERCISE']),
    ...mapActions('common', ['showLoading', 'hideLoading']),
    getExerciseList(query = '', isLoadData) {
      this.showLoading()
      this.isLoadDataExercise = true
      let paramsTemp = queryString.stringify({
        ...{
          limit: 10,
          page: 0,
          sortBy: 'createdDate',
          sort_direction: 'DESC',
          nama: query,
          questionType: this.activityItemType.value === 'INSTRUCTOR_FEEDBACK' ? 'SURVEY' : this.activityItemType.value === 'POST_TEST' ? 'EXAM' :this.activityItemType.value
        }
      })
      this.GET_LIST_EXERCISE({
        params: paramsTemp,
        customerId: this.customerId
      }).then(() => {
        this.hideLoading()
        this.isLoadDataExercise = false
      })
    },
    defineTimeConstrant(startTime, endTime) {
      let index = 0
      if (startTime && endTime) index = 1 // time constrant is start time and end time
      if (endTime && !startTime) index = 2 // time constrant is only end time
      return this.constraintListManualTask[index] // no time constrant
    },
    deleteInstructorSelected() {
      this.selectedInstructor = null
      this.$emit('deleteInstructorActivityItem', this.parentIndex, this.index)
    },
    getInstructorList(keyword, isLoadData) {
      this.isLoadDataInstructor = true
      let paramsTemp = queryString.stringify({
        ...{
          size: 500,
          page: 0,
          direction: 'DESC',
          program: '',
          sortBy: 'createdDate',
          keyword
        }
      })
      if (keyword || this.initialInstructorList === null) {
        this.GET_LIST_INSTRUCTUR({
          params: paramsTemp,
          customerId: this.customerId
        }).then(() => {
          if (isLoadData) {
            this.selectedInstructor = this.list_instructur.filter((instructor) => instructor.id === this.data.expert_id)[0]
          }
        })
      } else if (isLoadData) {
        this.selectedInstructor = this.initialInstructorList.filter((instructor) => instructor.id === this.data.expert_id)[0]
      } else {
        this.RESET_INSTRUCTUR_LIST({ data: this.initialInstructorList })
        if (isLoadData) {
          this.selectedInstructor = this.list_instructur.filter((instructor) => instructor.id === this.data.expert_id)[0]
        }
      }
      this.isLoadDataInstructor = false
    },
    changePosition(position, index) {
      this.$emit('changePositionChild', { position, index })
    },
    giveWarn() {
      this.data.warned = true
    },
    getStartMinuteRange() {
      if (this.endTimeOfDay) {
        if (this.startTimeOfDay && this.startTimeOfDay.HH === this.endTimeOfDay.HH) {
          return [[0, this.endTimeOfDay.mm]]
        }
      } else {
        return [[0, 59]]
      }
    },
    getStartHourRange() {
      if (this.endTimeOfDay) {
        return [[0, this.endTimeOfDay.HH]]
      } else {
        return [[0, 23]]
      }
    },
    getEndMinuteRange() {
      if (this.startTimeOfDay) {
        if (this.endTimeOfDay && this.endTimeOfDay.HH === this.startTimeOfDay.HH) {
          return [[this.startTimeOfDay.mm, 59]]
        }
      } else {
        return [[0, 59]]
      }
    },
    getEndHourRange() {
      if (this.startTimeOfDay) {
        return [[this.startTimeOfDay.HH, 23]]
      } else {
        return [[0, 23]]
      }
    },
    setSelectedVideo(video) {
      this.selectedVideo = video.title
      this.data.itemReferenceId = video.id
    },
    setSelectedLowResVideo(video) {
      this.selectedLowResVideo = video.title
      this.data.itemLowResReferenceId = video.id
    },
    toggleGallery() {
      this.visibleGallery = !this.visibleGallery
    },
    toggleLowResGallery() {
      this.visibleLowResGallery = !this.visibleLowResGallery
    },
    deleteItem(index) {
      this.$emit('deleteChild', index)
    },
    getExercise() {
      this.classIdError = null
      if (this.isEditMode && !this.classId) {
        this.classIdError = 'Please make sure the class is already selected!'
        return
      }
      if (this.data.itemReferenceId) {
        this.itemReferenceId = this.activityReference.filter((item) => {
          return item.id == this.data.itemReferenceId
        })[0]
      }
      if (this.data.durationInMinutes) {
        this.duration = this.durationList.filter((item) => {
          return item.value === this.data.durationInMinutes
        })[0]
      }
      if (this.data.timeConstraint) {
        this.constraint = this.constraintList.filter((item) => {
          return item.value === this.data.timeConstraint
        })[0]
      } else {
        this.data.timeConstraint = this.constraintList[0].value
        this.constraint = this.constraintList[0]
      }
      if (this.activityItemType.value === 'PRETEST' || this.activityItemType.value === 'QUIZ' || this.activityItemType.value === 'EXAM' || this.activityItemType.value === 'MANUAL_TASK' || this.activityItemType.value === 'POST_TEST') {
        this.startDate = this.data.startTime ? this.data.startTime : null
        this.endDate = this.data.endTime ? this.data.endTime : null
        // this.limited = this.data.retakeable ? !this.data.retakeable : true
        this.isNotRetakeable = this.isEditMode ? !this.data.retakeable : this.data.retakeable
      }
    }
  }
}
</script>
<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.label {
  height: 100%;
  margin-bottom: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 767px) {
    height: auto;
  }
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.rounded-lg {
  border-radius: 8px !important;
}
.py-3 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.date {
  &::v-deep .vdatetime-input {
    padding: 12px 44px 12px 12px;
    border-radius: 8px;
    width: 100%;
    border: 1px solid #d6d6d6;
    font-size: 16px;
    cursor: pointer;
  }
}
.date2 {
  &::v-deep .vdatetime-input {
    padding: 12px 44px 12px 12px;
    border-radius: 8px;
    width: 100%;
    border: 1px solid #d6d6d6;
    font-size: 16px;
    cursor: not-allowed;
  }
}
.time {
  &::v-deep .time-picker {
    display: block;
    width: 100%;
    font-size: 16px;
    input {
      width: 100%;
      padding: 12px;
      font-size: 1rem;
      line-height: 1.5rem;
      height: auto;
      cursor: pointer;
      border-radius: 8px;
    }
  }
}
.styled-checkbox {
  position: absolute;
  opacity: 0;
  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 0;
    display: inline-flex;
    align-items: center;
    color: #58595b;
  }
  & + label:before {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid #3b82f6;
    border-radius: 4px;
    margin-right: 10px;
  }
  &:hover + label:before {
    background: #3b82f6;
  }
  &:checked + label:before {
    background: #3b82f6;
  }
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}

[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #666;
  gap: 8px;
  font-size: 14px;
}

[type='radio']:checked + label span {
  font-weight: bold;
}

[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  content: '';
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: transparent;
  display: inline-block;
  flex: 0 0 24px;
}

[type='radio']:checked + label:before {
  border: 1px solid #3b82f6;
}

[type='radio']:not(:checked) + label:before {
  border: 1px solid #c2c2c2;
}

[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  width: 16px;
  height: 16px;
  background: #3b82f6;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.is-invalid {
  background-color: rgba(235, 87, 87, 0.1);
  border: 2px solid rgb(235, 87, 87);
}
</style>
