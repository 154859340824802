var render = function () {
  var _vm$isError, _vm$activityItemType, _vm$selectedWorkflow, _vm$activityItemTypeS, _vm$activityItemTypeS2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "child-activity"
  }, [_c('div', {
    staticClass: "flex justify-between items-center mb-2"
  }, [_c('p', {
    staticClass: "text-sm font-bold mb-2 text-neutral-500"
  }, [_vm._v("Activity Item " + _vm._s(_vm.index + 1))]), _c('div', {
    staticClass: "flex justify-between items-center"
  }, [_vm.index !== 0 ? _c('div', {
    staticClass: "flex justify-center items-center mr-5"
  }, [_c('button', {
    staticClass: "mr-2 p-1 border rounded transition-all outline-none inline-flex justify-center items-center gap-2 bg-transparent border-yellow-primary text-yellow-primary hover:text-yellow-300 hover:border-yellow-300 focus:outline-none",
    on: {
      "click": function click($event) {
        return _vm.changePosition('up', _vm.index);
      }
    }
  }, [_c('Chevron', {
    attrs: {
      "direction": "up",
      "height": "16",
      "width": "16",
      "color": "#3b82f6"
    }
  })], 1), _c('button', {
    staticClass: "p-1 border rounded transition-all outline-none inline-flex justify-center items-center gap-2 bg-transparent border-yellow-primary text-yellow-primary hover:text-yellow-300 hover:border-yellow-300 focus:outline-none",
    class: [_vm.isLastIndex ? 'border-grey-field hover:border-grey-field cursor-not-allowed' : ''],
    attrs: {
      "disabled": _vm.isLastIndex
    },
    on: {
      "click": function click($event) {
        return _vm.changePosition('down', _vm.index);
      }
    }
  }, [_c('Chevron', {
    attrs: {
      "direction": "down",
      "height": "16",
      "width": "16",
      "color": _vm.isLastIndex ? '#D6D6D6' : '#3b82f6'
    }
  })], 1)]) : _vm._e(), _vm.index !== 0 ? _c('Button', {
    attrs: {
      "type": "secondary",
      "size": "icon",
      "icon": function icon() {
        return import(
        /* webpackChunkName: 'icon' */
        '@/components/Icons/Trash');
      }
    },
    on: {
      "action": function action($event) {
        return _vm.deleteItem(_vm.index);
      }
    }
  }) : _vm._e()], 1)]), _c('div', {
    staticClass: "bg-neutral-100 p-4 rounded-lg mb-6",
    class: {
      'is-invalid': (_vm$isError = _vm.isError) === null || _vm$isError === void 0 ? void 0 : _vm$isError.error
    }
  }, [_c('div', {
    staticClass: "w-3/5"
  }, [_c('div', {
    staticClass: "mb-4"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "isLabelRequire": "",
      "label": "Title",
      "borderEnabled": true,
      "placeholder": "Child Activity Title",
      "error": _vm.error && _vm.error['title']
    },
    model: {
      value: _vm.data.title,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "title", $$v);
      },
      expression: "data.title"
    }
  })], 1), _c('div', {
    staticClass: "mb-4"
  }, [_vm._m(0), _c('div', {
    staticClass: "bg-white"
  }, [_c('quill-editor', {
    attrs: {
      "options": _vm.option
    },
    model: {
      value: _vm.data.description,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "description", $$v);
      },
      expression: "data.description"
    }
  })], 1), _vm.error ? _c('div', {
    staticClass: "pl-3 text-system-error text-left mt-1 text-xs"
  }, [_vm._v(" " + _vm._s(_vm.error['description']) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "mb-4"
  }, [_vm._m(1), _c('Dropdown', {
    attrs: {
      "disabled": _vm.disableSelectActivityType,
      "options": _vm.activityType,
      "optionLabel": "label",
      "default": _vm.activityItemType,
      "placeholder": "Select Activity Type",
      "error": _vm.error && _vm.error['activityItemType']
    },
    model: {
      value: _vm.activityItemType,
      callback: function callback($$v) {
        _vm.activityItemType = $$v;
      },
      expression: "activityItemType"
    }
  })], 1), _vm.visibleType ? _c('div', {
    staticClass: "py-4"
  }, [_vm.fileSelectionTitle ? _c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v(_vm._s(_vm.fileSelectionTitle)), _c('span', {
    staticClass: "text-red"
  }, [_vm._v(" *")]), _vm._v(" "), _vm.classIdError ? _c('span', {
    staticClass: "text-xs text-neutral-300"
  }, [_vm._v("(" + _vm._s(_vm.classIdError) + ")")]) : _vm._e()]) : _vm._e(), _vm.activityItemType.value === 'VIDEO' || _vm.activityItemType.value === 'DOCUMENT' ? _c('div', {
    staticClass: "gallery"
  }, [_c('div', {
    staticClass: "relative"
  }, [_c('label', {
    staticClass: "label absolute right-0 p-3 cursor-pointer text-white font-medium mdmax:text-xs bg-yellow-primary rounded-tr-lg rounded-br-lg",
    on: {
      "click": function click($event) {
        return _vm.toggleGallery();
      }
    }
  }, [_vm._v("Browse")]), _c('div', {
    staticClass: "input pl-3 pr-24 py-5 focus:outline-none focus:ring focus:ring-primary-500 border border-neutral-300 w-full rounded-lg text-neutral-300 mdmax:text-xs"
  }, [_vm._v(" " + _vm._s(_vm.selectedVideo) + " ")])]), _vm.error ? _c('div', {
    staticClass: "pl-3 text-system-error text-left mt-1 text-xs"
  }, [_vm._v(" " + _vm._s(_vm.error['itemReferenceId']) + " ")]) : _vm._e()]) : _vm._e(), _vm.activityItemType.value === 'VIDEO' ? _c('div', {
    staticClass: "gallery py-4"
  }, [_c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v("Lower Resolution")]), _c('div', {
    staticClass: "relative"
  }, [_c('label', {
    staticClass: "label absolute right-0 p-3 cursor-pointer text-white font-medium mdmax:text-xs bg-yellow-primary rounded-tr-lg rounded-br-lg",
    on: {
      "click": function click($event) {
        return _vm.toggleLowResGallery();
      }
    }
  }, [_vm._v("Browse")]), _c('div', {
    staticClass: "input pl-3 pr-24 py-5 focus:outline-none focus:ring focus:ring-primary-500 border border-neutral-300 w-full rounded-lg text-neutral-300 mdmax:text-xs"
  }, [_vm._v(" " + _vm._s(_vm.selectedLowResVideo) + " ")])]), _vm.error ? _c('div', {
    staticClass: "pl-3 text-system-error text-left mt-1 text-xs"
  }, [_vm._v(" " + _vm._s(_vm.error['itemLowResReferenceId']) + " ")]) : _vm._e()]) : _vm.activityItemType.value === 'OFFLINE_MEETING' && !_vm.isTemplateAct ? _c('div', {
    staticClass: "offline"
  }, [_c('div', {
    staticClass: "mb-4 date"
  }, [_vm._m(2), _c('div', {
    staticClass: "relative"
  }, [_c('span', {
    staticClass: "inline-block absolute right-4 top-1/2 transform -translate-y-1/2"
  }, [_c('Calendar', {
    attrs: {
      "color": "neutral-500"
    }
  })], 1), _c('datetime', {
    attrs: {
      "type": "datetime",
      "zone": "Asia/Jakarta",
      "format": "yyyy-MM-dd HH:mm",
      "max-datetime": _vm.endDate,
      "placeholder": "Select Date"
    },
    model: {
      value: _vm.startDate,
      callback: function callback($$v) {
        _vm.startDate = $$v;
      },
      expression: "startDate"
    }
  })], 1), _vm.error && _vm.error['startTime'] ? _c('div', {
    staticClass: "pl-3 text-system-error text-left mt-1 text-xs"
  }, [_vm._v(" " + _vm._s(_vm.error && _vm.error['startTime']) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "date"
  }, [_vm._m(3), _c('div', {
    staticClass: "relative"
  }, [_c('span', {
    staticClass: "inline-block absolute right-4 top-1/2 transform -translate-y-1/2"
  }, [_c('Calendar', {
    attrs: {
      "color": "neutral-500"
    }
  })], 1), _c('datetime', {
    attrs: {
      "type": "datetime",
      "zone": "Asia/Jakarta",
      "format": "yyyy-MM-dd HH:mm",
      "min-datetime": _vm.startDate,
      "placeholder": "Select Date"
    },
    model: {
      value: _vm.endDate,
      callback: function callback($$v) {
        _vm.endDate = $$v;
      },
      expression: "endDate"
    }
  })], 1), _vm.error && _vm.error['endTime'] ? _c('div', {
    staticClass: "pl-3 text-system-error text-left mt-1 text-xs"
  }, [_vm._v(" " + _vm._s(_vm.error && _vm.error['endTime']) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "mt-4"
  }, [_c('div', {
    staticClass: "flex gap-3"
  }, [_c('div', {
    staticClass: "w-full"
  }, [_c('Dropdown', {
    attrs: {
      "options": _vm.filterInstructor,
      "default": _vm.selectedInstructor,
      "disabled": !_vm.startDate || !_vm.endDate,
      "placeholder": "Select Instructor",
      "label": "Activity Item Instructor (optional)",
      "optionLabel": "name",
      "isLoadData": _vm.isLoadDataInstructor,
      "enableSearch": ""
    },
    on: {
      "search": _vm.getInstructorList
    },
    model: {
      value: _vm.selectedInstructor,
      callback: function callback($$v) {
        _vm.selectedInstructor = $$v;
      },
      expression: "selectedInstructor"
    }
  })], 1), _vm.selectedInstructor ? _c('div', {
    staticClass: "flex items-center pt-5"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Reset",
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        return _vm.deleteInstructorSelected();
      }
    }
  })], 1) : _vm._e()]), _c('p', {
    staticClass: "text-xs mt-2 text-neutral-400 pl-3"
  }, [_vm._v("If you leave it empty, instructor will be taken from Activity Instructor / Instructor (Default)")])])]) : _vm.activityItemType.value === 'PRETEST' || _vm.activityItemType.value === 'QUIZ' || _vm.activityItemType.value === 'EXAM' || _vm.activityItemType.value === 'POST_TEST' ? _c('div', {
    staticClass: "offline"
  }, [_c('div', {
    staticClass: "mb-4"
  }, [_c('Dropdown', {
    attrs: {
      "options": _vm.activityReference,
      "default": _vm.itemReferenceId,
      "optionKey": "id",
      "optionLabel": "name",
      "placeholder": "Select",
      "error": _vm.error && _vm.error['itemReferenceId'],
      "isLoadData": _vm.isLoadDataExercise,
      "enableSearch": ""
    },
    on: {
      "search": _vm.getExerciseList
    },
    model: {
      value: _vm.itemReferenceId,
      callback: function callback($$v) {
        _vm.itemReferenceId = $$v;
      },
      expression: "itemReferenceId"
    }
  })], 1), _c('div', {
    staticClass: "mb-4"
  }, [_vm._m(4), _c('Dropdown', {
    attrs: {
      "options": _vm.constraintList,
      "optionLabel": "label",
      "default": _vm.constraint
    },
    model: {
      value: _vm.constraint,
      callback: function callback($$v) {
        _vm.constraint = $$v;
      },
      expression: "constraint"
    }
  }), _c('span', {
    ref: "warn"
  })], 1), _vm.constraint && (_vm.constraint.value === 1 || _vm.constraint.value === 2 || _vm.constraint.value === 3) ? _c('div', {
    staticClass: "mb-4 date"
  }, [_vm._m(5), _c('div', {
    staticClass: "relative"
  }, [_c('span', {
    staticClass: "inline-block absolute right-4 top-1/2 transform -translate-y-1/2"
  }, [_c('Calendar', {
    attrs: {
      "color": "neutral-500"
    }
  })], 1), _c('datetime', {
    attrs: {
      "type": "datetime",
      "zone": "Asia/Jakarta",
      "format": "yyyy-MM-dd HH:mm",
      "max-datetime": _vm.endDate,
      "placeholder": "Select Date"
    },
    model: {
      value: _vm.startDate,
      callback: function callback($$v) {
        _vm.startDate = $$v;
      },
      expression: "startDate"
    }
  })], 1), _vm.error && _vm.error['startTime'] ? _c('div', {
    staticClass: "pl-3 text-system-error text-left mt-1 text-xs"
  }, [_vm._v(" " + _vm._s(_vm.error && _vm.error['startTime']) + " ")]) : _vm._e(), _c('div', {
    staticClass: "text-xs mt-2 ml-4 text-gray-500"
  }, [_vm._v("Exercise can be accessed in LMS after Start Time")])]) : _vm._e(), _vm.constraint && (_vm.constraint.value === 1 || _vm.constraint.value === 3) ? _c('div', {
    staticClass: "date"
  }, [_vm._m(6), !this.duration ? _c('div', {
    staticClass: "relative"
  }, [_c('span', {
    staticClass: "inline-block absolute right-4 top-1/2 transform -translate-y-1/2"
  }, [_c('Calendar', {
    attrs: {
      "color": "neutral-500"
    }
  })], 1), _c('datetime', {
    attrs: {
      "type": "datetime",
      "id": "myDatetime",
      "zone": "Asia/Jakarta",
      "format": "yyyy-MM-dd HH:mm",
      "min-datetime": _vm.startDate,
      "placeholder": "Select Date"
    },
    model: {
      value: _vm.endDate,
      callback: function callback($$v) {
        _vm.endDate = $$v;
      },
      expression: "endDate"
    }
  })], 1) : _c('div', {
    staticClass: "relative date2"
  }, [_c('span', {
    staticClass: "inline-block absolute right-4 top-1/2 transform -translate-y-1/2"
  }, [_c('Calendar', {
    attrs: {
      "color": "neutral-500"
    }
  })], 1), _c('datetime', {
    staticClass: "cursor-not-allowed",
    attrs: {
      "type": "datetime",
      "id": "myDatetime",
      "zone": "Asia/Jakarta",
      "format": "yyyy-MM-dd HH:mm",
      "min-datetime": _vm.startDate,
      "placeholder": "Select Date"
    },
    model: {
      value: _vm.endDate,
      callback: function callback($$v) {
        _vm.endDate = $$v;
      },
      expression: "endDate"
    }
  })], 1), _vm.error && _vm.error['endTime'] ? _c('div', {
    staticClass: "pl-3 text-system-error text-left mt-1 text-xs"
  }, [_vm._v(" " + _vm._s(_vm.error && _vm.error['endTime']) + " ")]) : _vm._e()]) : _vm._e(), _vm.constraint && (_vm.constraint.value === 2 || _vm.constraint.value === 3) ? _c('div', {
    staticClass: "mb-4 mt-4"
  }, [_vm._m(7), _c('Dropdown', {
    attrs: {
      "options": _vm.durationList,
      "optionLabel": "label",
      "default": _vm.duration,
      "placeholder": "Select Duration",
      "error": _vm.error && _vm.error['durationInMinutes']
    },
    model: {
      value: _vm.duration,
      callback: function callback($$v) {
        _vm.duration = $$v;
      },
      expression: "duration"
    }
  })], 1) : _vm._e()]) : _vm.activityItemType.value === 'MANUAL_TASK' ? _c('div', {
    staticClass: "offline"
  }, [_c('div', {
    staticClass: "mb-4"
  }, [_vm._m(8), _c('Dropdown', {
    attrs: {
      "options": _vm.constraintListManualTask,
      "optionLabel": "label",
      "default": _vm.constraint
    },
    model: {
      value: _vm.constraint,
      callback: function callback($$v) {
        _vm.constraint = $$v;
      },
      expression: "constraint"
    }
  }), _c('span', {
    ref: "warn"
  })], 1), _vm.constraint && _vm.constraint.value === 1 ? _c('div', {
    staticClass: "mb-4 date"
  }, [_vm._m(9), _c('div', {
    staticClass: "relative"
  }, [_c('span', {
    staticClass: "inline-block absolute right-4 top-1/2 transform -translate-y-1/2"
  }, [_c('Calendar', {
    attrs: {
      "color": "neutral-500"
    }
  })], 1), _c('datetime', {
    attrs: {
      "type": "datetime",
      "zone": "Asia/Jakarta",
      "format": "yyyy-MM-dd HH:mm",
      "max-datetime": _vm.endDate,
      "placeholder": "Select Date"
    },
    model: {
      value: _vm.startDate,
      callback: function callback($$v) {
        _vm.startDate = $$v;
      },
      expression: "startDate"
    }
  })], 1), _vm.error && _vm.error['startTime'] ? _c('div', {
    staticClass: "pl-3 text-system-error text-left mt-1 text-xs"
  }, [_vm._v(" " + _vm._s(_vm.error && _vm.error['startTime']) + " ")]) : _vm._e(), _c('div', {
    staticClass: "text-xs mt-2 ml-4 text-gray-500"
  }, [_vm._v("Manual task can be accessed in LMS after Start Time")])]) : _vm._e(), _vm.constraint && (_vm.constraint.value === 1 || _vm.constraint.value === 2) ? _c('div', {
    staticClass: "mb-4 date"
  }, [_vm._m(10), _c('div', {
    staticClass: "relative"
  }, [_c('span', {
    staticClass: "inline-block absolute right-4 top-1/2 transform -translate-y-1/2"
  }, [_c('Calendar', {
    attrs: {
      "color": "neutral-500"
    }
  })], 1), _c('datetime', {
    attrs: {
      "disabled": _vm.constraint.value === 2 ? false : _vm.startDate ? false : true,
      "type": "datetime",
      "zone": "Asia/Jakarta",
      "format": "yyyy-MM-dd HH:mm",
      "min-datetime": _vm.constraint.value === 2 ? null : _vm.startDate,
      "placeholder": "Select Date"
    },
    model: {
      value: _vm.endDate,
      callback: function callback($$v) {
        _vm.endDate = $$v;
      },
      expression: "endDate"
    }
  })], 1), _vm.error && _vm.error['endTime'] ? _c('div', {
    staticClass: "pl-3 text-system-error text-left mt-1 text-xs"
  }, [_vm._v(" " + _vm._s(_vm.error && _vm.error['endTime']) + " ")]) : _vm._e()]) : _vm._e()]) : _vm.activityItemType.value === 'RECURRING_ONLINE' || _vm.activityItemType.value === 'WEBINAR' && !_vm.isTemplateAct ? _c('div', {
    staticClass: "offline"
  }, [_c('div', {
    class: "mb-4 ".concat(_vm.activityItemType.value === 'WEBINAR' ? 'date' : '')
  }, [_c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v(_vm._s(_vm.activityItemType.value === 'WEBINAR' ? 'Date' : 'Day')), _c('span', {
    staticClass: "text-red"
  }, [_vm._v(" *")])]), _vm.activityItemType.value === 'WEBINAR' ? _c('div', {
    staticClass: "relative"
  }, [_c('span', {
    staticClass: "inline-block absolute right-4 top-1/2 transform -translate-y-1/2"
  }, [_c('Calendar', {
    attrs: {
      "color": "neutral-500"
    }
  })], 1), _c('datetime', {
    attrs: {
      "zone": "Asia/Jakarta",
      "format": "yyyy-MM-dd",
      "placeholder": "Select Date"
    },
    model: {
      value: _vm.startDate,
      callback: function callback($$v) {
        _vm.startDate = $$v;
      },
      expression: "startDate"
    }
  })], 1) : _c('Dropdown', {
    attrs: {
      "options": _vm.dayOfWeekList,
      "optionLabel": "label",
      "default": _vm.dayOfWeek,
      "placeholder": "Select Day",
      "error": _vm.error && _vm.error['dayOfWeek']
    },
    model: {
      value: _vm.dayOfWeek,
      callback: function callback($$v) {
        _vm.dayOfWeek = $$v;
      },
      expression: "dayOfWeek"
    }
  })], 1), _c('div', {
    staticClass: "mb-4"
  }, [_vm._m(11), _c('div', {
    staticClass: "relative time"
  }, [_c('span', {
    staticClass: "inline-block absolute right-4 top-1/2 transform -translate-y-1/2 z-1"
  }, [_c('Calendar', {
    attrs: {
      "color": "neutral-500"
    }
  })], 1), _c('vue-timepicker', {
    key: "start-".concat(_vm.counter),
    attrs: {
      "hide-clear-button": "",
      "close-on-complete": "",
      "placeholder": "Select Time",
      "hour-range": _vm.getStartHourRange(),
      "minute-range": _vm.getStartMinuteRange(),
      "disabled": _vm.firstDisable
    },
    model: {
      value: _vm.startTimeOfDay,
      callback: function callback($$v) {
        _vm.startTimeOfDay = $$v;
      },
      expression: "startTimeOfDay"
    }
  })], 1), _vm.error && _vm.error['startTimeOfDay'] ? _c('div', {
    staticClass: "pl-3 text-system-error text-left mt-1 text-xs"
  }, [_vm._v(" " + _vm._s(_vm.error && _vm.error['startTimeOfDay']) + " ")]) : _vm._e()]), _c('div', {}, [_vm._m(12), _c('div', {
    staticClass: "relative time"
  }, [_c('span', {
    staticClass: "inline-block absolute right-4 top-1/2 transform -translate-y-1/2 z-1"
  }, [_c('Calendar', {
    attrs: {
      "color": "neutral-500"
    }
  })], 1), _c('vue-timepicker', {
    key: "end-".concat(_vm.counter),
    attrs: {
      "hide-clear-button": "",
      "close-on-complete": "",
      "placeholder": "Select Time",
      "hour-range": _vm.getEndHourRange(),
      "minute-range": _vm.getEndMinuteRange(),
      "disabled": _vm.secondDisable
    },
    model: {
      value: _vm.endTimeOfDay,
      callback: function callback($$v) {
        _vm.endTimeOfDay = $$v;
      },
      expression: "endTimeOfDay"
    }
  })], 1), _vm.error && _vm.error['endTimeOfDay'] ? _c('div', {
    staticClass: "pl-3 text-system-error text-left mt-1 text-xs"
  }, [_vm._v(" " + _vm._s(_vm.error && _vm.error['endTimeOfDay']) + " ")]) : _vm._e()]), _vm.activityItemType.value === 'WEBINAR' && !_vm.isTemplateAct ? _c('div', {
    staticClass: "mt-4 w-full"
  }, [_c('div', {
    staticClass: "flex gap-3"
  }, [_c('div', {
    staticClass: "w-full"
  }, [_c('Dropdown', {
    attrs: {
      "options": _vm.filterInstructor,
      "default": _vm.selectedInstructor,
      "disabled": _vm.thirdDisable,
      "placeholder": "Select Instructor",
      "label": "Activity Item Instructor (optional)",
      "optionLabel": "name",
      "isLoadData": _vm.isLoadDataInstructor,
      "enableSearch": ""
    },
    on: {
      "search": _vm.getInstructorList
    },
    model: {
      value: _vm.selectedInstructor,
      callback: function callback($$v) {
        _vm.selectedInstructor = $$v;
      },
      expression: "selectedInstructor"
    }
  })], 1), _vm.selectedInstructor ? _c('div', {
    staticClass: "flex items-center pt-5"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Reset",
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        return _vm.deleteInstructorSelected();
      }
    }
  })], 1) : _vm._e()]), _c('p', {
    staticClass: "text-xs mt-2 text-neutral-400 pl-3"
  }, [_vm._v("If you leave it empty, instructor will be taken from Activity Instructor / Instructor (Default)")])]) : _vm._e(), _vm.activityItemType.value === 'WEBINAR' && !_vm.isTemplateAct ? _c('div', {
    staticClass: "mt-4"
  }, [_c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v("Webinar Link Setting (optional)")]), _c('div', {
    staticClass: "flex gap-10 items-center mt-3 mb-8"
  }, [_c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.autoGenerated,
      expression: "autoGenerated"
    }],
    attrs: {
      "type": "radio",
      "id": "manual-".concat(_vm.parentIndex, "-").concat(_vm.index)
    },
    domProps: {
      "value": false,
      "checked": _vm._q(_vm.autoGenerated, false)
    },
    on: {
      "change": function change($event) {
        _vm.autoGenerated = false;
      }
    }
  }), _c('label', {
    staticClass: "text-black font-medium",
    attrs: {
      "for": "manual-".concat(_vm.parentIndex, "-").concat(_vm.index)
    }
  }, [_c('span', [_vm._v("Manual Input")])])]), _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.autoGenerated,
      expression: "autoGenerated"
    }],
    attrs: {
      "type": "radio",
      "id": "auto-".concat(_vm.parentIndex, "-").concat(_vm.index)
    },
    domProps: {
      "value": true,
      "checked": _vm._q(_vm.autoGenerated, true)
    },
    on: {
      "change": function change($event) {
        _vm.autoGenerated = true;
      }
    }
  }), _c('label', {
    attrs: {
      "for": "auto-".concat(_vm.parentIndex, "-").concat(_vm.index)
    }
  }, [_vm._m(13)])])]), !_vm.autoGenerated ? _c('div', [_c('TextField', {
    attrs: {
      "type": "text",
      "borderEnabled": "",
      "placeholder": "Enter webinar link"
    },
    model: {
      value: _vm.webinarLink,
      callback: function callback($$v) {
        _vm.webinarLink = $$v;
      },
      expression: "webinarLink"
    }
  }), _c('p', {
    staticClass: "text-xs mt-2 text-neutral-400 pl-3"
  }, [_vm._v("If empty, activity will use link from General tab")])], 1) : _vm._e(), _vm.isEditMode && _vm.autoGenerated && _vm.defaultStateForAutoGenerated ? _c('div', {
    staticClass: "flex items-center gap-2"
  }, [_c('img', {
    staticClass: "w-6",
    attrs: {
      "src": require("@/assets/images/zoom.png")
    }
  }), _c('p', {
    staticClass: "text-xs font-medium text-yellow"
  }, [_vm._v(_vm._s(_vm.webinarLink))])]) : _vm._e()]) : _vm._e()]) : _vm.activityItemType.value === 'OPEN_LINK' ? _c('div', {
    staticClass: "offline"
  }, [_c('div', {
    staticClass: "mb-4"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Label",
      "borderEnabled": "",
      "isLabelRequire": "",
      "placeholder": "Link Label",
      "error": _vm.error && _vm.error['label']
    },
    model: {
      value: _vm.forLabel,
      callback: function callback($$v) {
        _vm.forLabel = $$v;
      },
      expression: "forLabel"
    }
  })], 1), _c('div', {}, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Link",
      "borderEnabled": "",
      "isLabelRequire": "",
      "placeholder": "Link",
      "error": _vm.error && _vm.error['link']
    },
    model: {
      value: _vm.forLink,
      callback: function callback($$v) {
        _vm.forLink = $$v;
      },
      expression: "forLink"
    }
  })], 1)]) : ((_vm$activityItemType = _vm.activityItemType) === null || _vm$activityItemType === void 0 ? void 0 : _vm$activityItemType.value) === 'REFLECTIVE_JOURNAL_ESSAY' ? _c('div', [_c('div', {
    staticClass: "mb-4"
  }, [_c('TextArea', {
    attrs: {
      "label": "Soal 1",
      "isLabelRequire": "",
      "borderEnabled": true,
      "placeholder": "Soal 1",
      "error": _vm.error && _vm.error['soal_1']
    },
    model: {
      value: _vm.data.reflectiveJournalQuestion1,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "reflectiveJournalQuestion1", $$v);
      },
      expression: "data.reflectiveJournalQuestion1"
    }
  })], 1), _c('div', {
    staticClass: "mb-4"
  }, [_c('TextArea', {
    attrs: {
      "label": "Soal 2",
      "isLabelRequire": "",
      "borderEnabled": true,
      "placeholder": "Soal 2",
      "error": _vm.error && _vm.error['soal_2']
    },
    model: {
      value: _vm.data.reflectiveJournalQuestion2,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "reflectiveJournalQuestion2", $$v);
      },
      expression: "data.reflectiveJournalQuestion2"
    }
  })], 1)]) : _vm.activityItemType.value != 'OFFLINE_MEETING' && _vm.activityItemType.value != 'WEBINAR' && _vm.activityItemType.value != 'REFLECTIVE_JOURNAL' && _vm.activityItemType.value != 'REFLECTIVE_JOURNAL_ESSAY' && _vm.activityItemType.value != 'DOCUMENT' ? _c('Dropdown', {
    attrs: {
      "options": _vm.activityReference,
      "default": _vm.itemReferenceId,
      "optionKey": "id",
      "optionLabel": "name",
      "placeholder": "Select ".concat(_vm.activityItemType.label),
      "error": _vm.error && _vm.error['itemReferenceId'],
      "isLoadData": _vm.isLoadDataExercise,
      "enableSearch": ""
    },
    on: {
      "search": _vm.getExerciseList
    },
    model: {
      value: _vm.itemReferenceId,
      callback: function callback($$v) {
        _vm.itemReferenceId = $$v;
      },
      expression: "itemReferenceId"
    }
  }) : _vm._e(), _vm.activityItemType.value === 'INSTRUCTOR_FEEDBACK' ? _c('div', {
    staticClass: "mt-4"
  }, [_vm._m(14), _c('Dropdown', {
    attrs: {
      "options": _vm.list_instructur,
      "default": _vm.selectedInstructor,
      "placeholder": "Select Instructor",
      "optionLabel": "name",
      "isLoadData": _vm.isLoadDataInstructor,
      "enableSearch": ""
    },
    on: {
      "search": _vm.getInstructorList
    },
    model: {
      value: _vm.selectedInstructor,
      callback: function callback($$v) {
        _vm.selectedInstructor = $$v;
      },
      expression: "selectedInstructor"
    }
  })], 1) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activityItemType.value === 'PRETEST' || _vm.activityItemType.value === 'QUIZ' || _vm.activityItemType.value === 'EXAM' || _vm.activityItemType.value === 'POST_TEST',
      expression: "activityItemType.value === 'PRETEST' || activityItemType.value === 'QUIZ' || activityItemType.value === 'EXAM' || activityItemType.value === 'POST_TEST'"
    }],
    staticClass: "mt-4"
  }, [_c('TextField', {
    attrs: {
      "type": "number",
      "label": "Passing Grade ( 0-100 )",
      "borderEnabled": "",
      "placeholder": "Set Passing Grade",
      "error": _vm.error && _vm.error['minimumScore']
    },
    model: {
      value: _vm.minimumScore,
      callback: function callback($$v) {
        _vm.minimumScore = $$v;
      },
      expression: "minimumScore"
    }
  })], 1), _c('div', {
    staticClass: "flex items-center mt-3 gap-10"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activityItemType.value === 'PRETEST' || _vm.activityItemType.value === 'QUIZ' || _vm.activityItemType.value === 'EXAM' || _vm.activityItemType.value === 'MANUAL_TASK' || _vm.activityItemType.value === 'POST_TEST',
      expression: "activityItemType.value === 'PRETEST' || activityItemType.value === 'QUIZ' || activityItemType.value === 'EXAM' || activityItemType.value === 'MANUAL_TASK' || activityItemType.value === 'POST_TEST'"
    }]
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.isNotRetakeable,
      expression: "isNotRetakeable"
    }],
    staticClass: "styled-checkbox",
    attrs: {
      "type": "checkbox",
      "id": _vm.activityItemType.value + '-limit-' + _vm.parentIndex + _vm.index
    },
    domProps: {
      "checked": Array.isArray(_vm.isNotRetakeable) ? _vm._i(_vm.isNotRetakeable, null) > -1 : _vm.isNotRetakeable
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.isNotRetakeable,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.isNotRetakeable = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.isNotRetakeable = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.isNotRetakeable = $$c;
        }
      }
    }
  }), _c('label', {
    staticClass: "text-sm",
    attrs: {
      "for": _vm.activityItemType.value + '-limit-' + _vm.parentIndex + _vm.index
    }
  }, [_vm._v("Limit to 1 Response")]), _c('p', {
    staticClass: "text-xs pl-4 ml-2 text-neutral-400 mb-2"
  }, [_vm._v("Student can only submit 1 response")])])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activityItemType.value === 'MANUAL_TASK' && ((_vm$selectedWorkflow = _vm.selectedWorkflow) === null || _vm$selectedWorkflow === void 0 ? void 0 : _vm$selectedWorkflow.code) === 'PRAKERJA_SCHEMA_NORMAL',
      expression: "activityItemType.value === 'MANUAL_TASK' && selectedWorkflow?.code === 'PRAKERJA_SCHEMA_NORMAL'"
    }]
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.data.generated_certificate,
      expression: "data.generated_certificate"
    }],
    staticClass: "styled-checkbox",
    attrs: {
      "type": "checkbox",
      "id": _vm.activityItemType.value + '-certificate-' + _vm.parentIndex + _vm.index
    },
    domProps: {
      "checked": Array.isArray(_vm.data.generated_certificate) ? _vm._i(_vm.data.generated_certificate, null) > -1 : _vm.data.generated_certificate
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.data.generated_certificate,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.data, "generated_certificate", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.data, "generated_certificate", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.data, "generated_certificate", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "text-sm",
    attrs: {
      "for": _vm.activityItemType.value + '-certificate-' + _vm.parentIndex + _vm.index
    }
  }, [_vm._v("Generate Certificate")]), _c('p', {
    staticClass: "text-xs pl-4 ml-2 text-neutral-400 mb-2"
  }, [_vm._v("Trigger to generate certificate")])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activityItemType.value === 'DOCUMENT',
      expression: "activityItemType.value === 'DOCUMENT'"
    }],
    staticClass: "flex items-center mb-4 mt-5"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.data.download_status,
      expression: "data.download_status"
    }],
    staticClass: "styled-checkbox",
    attrs: {
      "type": "checkbox",
      "id": 'activity-' + _vm.parentIndex + '-limit-' + _vm.index
    },
    domProps: {
      "checked": Array.isArray(_vm.data.download_status) ? _vm._i(_vm.data.download_status, null) > -1 : _vm.data.download_status
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.data.download_status,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.data, "download_status", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.data, "download_status", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.data, "download_status", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "text-sm",
    attrs: {
      "for": 'activity-' + _vm.parentIndex + '-limit-' + _vm.index
    }
  }, [_vm._v("Allow Student to download document")])])], 1) : _vm._e()])]), _c('Modal', {
    attrs: {
      "modalVisible": _vm.visibleGallery,
      "width": "80%",
      "id": "gallery"
    },
    on: {
      "close": function close($event) {
        return _vm.toggleGallery();
      }
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('GalleryModal', {
    attrs: {
      "modalTitle": "Select ".concat((_vm$activityItemTypeS = _vm.activityItemTypeSelected) === null || _vm$activityItemTypeS === void 0 ? void 0 : _vm$activityItemTypeS.toLowerCase()),
      "activityType": _vm.activityItemTypeSelected
    },
    on: {
      "selectVideo": _vm.setSelectedVideo,
      "close": function close($event) {
        return _vm.toggleGallery();
      }
    }
  })], 1)], 2), _c('Modal', {
    attrs: {
      "modalVisible": _vm.visibleLowResGallery,
      "width": "80%",
      "id": "galleryLowRes"
    },
    on: {
      "close": function close($event) {
        return _vm.toggleLowResGallery();
      }
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('GalleryModal', {
    attrs: {
      "modalTitle": "Select ".concat((_vm$activityItemTypeS2 = _vm.activityItemTypeSelected) === null || _vm$activityItemTypeS2 === void 0 ? void 0 : _vm$activityItemTypeS2.toLowerCase()),
      "activityType": _vm.activityItemTypeSelected
    },
    on: {
      "selectVideo": _vm.setSelectedLowResVideo,
      "close": function close($event) {
        return _vm.toggleLowResGallery();
      }
    }
  })], 1)], 2)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v("Description"), _c('span', {
    staticClass: "text-red"
  }, [_vm._v(" *")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v("Type"), _c('span', {
    staticClass: "text-red"
  }, [_vm._v(" *")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v("Start Time"), _c('span', {
    staticClass: "text-red"
  }, [_vm._v(" *")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v("End Time"), _c('span', {
    staticClass: "text-red"
  }, [_vm._v(" *")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v("Time Constraint"), _c('span', {
    staticClass: "text-red"
  }, [_vm._v(" *")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v("Start Time"), _c('span', {
    staticClass: "text-red"
  }, [_vm._v(" *")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v("End Time"), _c('span', {
    staticClass: "text-red"
  }, [_vm._v(" *")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v("Duration"), _c('span', {
    staticClass: "text-red"
  }, [_vm._v(" *")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v("Time Constraint"), _c('span', {
    staticClass: "text-red"
  }, [_vm._v(" *")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v("Start Time"), _c('span', {
    staticClass: "text-red"
  }, [_vm._v(" *")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v("End Time"), _c('span', {
    staticClass: "text-red"
  }, [_vm._v(" *")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v("Start Hour"), _c('span', {
    staticClass: "text-red"
  }, [_vm._v(" *")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v("End Hour"), _c('span', {
    staticClass: "text-red"
  }, [_vm._v(" *")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "relative"
  }, [_c('span', {
    staticClass: "block font-medium"
  }, [_vm._v("Auto-generated Zoom Link")]), _c('i', {
    staticClass: "text-xs not-italic text-neutral-500 absolute font-normal"
  }, [_vm._v("Zoom Integration required")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v("Select Instructor ( Feedback )"), _c('span', {
    staticClass: "text-red"
  }, [_vm._v(" *")])]);
}]

export { render, staticRenderFns }